import axios from 'axios';

const settings = {
  'url': 'https://litrif.com/cnty/whereami',
  'method': 'GET',
  'timeout': 0,
  'headers': {
    'Authorization': 'Basic c21zbHV4LmRlOjEyMzQ1'
  }
};

export async function getLocation () {
  try {
    let { data } = await axios(settings);
    return data;
  } catch (e) {
    console.log(e);
  }
}