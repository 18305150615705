import React, { useEffect, useState } from 'react';
import headerImg from '../images/header.png';
import { useTranslation } from 'react-i18next';

const Header = ({ logo }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className='container'>
      <div className='row align-items-center py-5'>
        <div className='col-xl-5'>
          <div className='text-uppercase fw-bold fs-2 text-primary-emphasis ls-5'>
            {logo}
          </div>
          <div className='fs-4 fw-bold'>
            {t('header.title')}
          </div>
          <div className='fs-6 mb-3'>
            {t('header.description')}
          </div>
          <button className='btn btn-primary'><a href='#features'>{t('header.cta')}</a></button>
        </div>
        <div className='col-xl-7'>
          <img src={headerImg} alt='' />
        </div>
      </div>
    </div>
  );
};

export default Header;