import React from 'react';
import { useTranslation } from 'react-i18next';

const System = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='container'>
      <div className='row mt-5'>
        <div className='col-xl-12'>
          <div className='text-center'>
            <div className='fs-2'>
              {t('system.title')}
            </div>
            <p>
              {t('system.description')}
            </p>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-4 p-4'>
          <div className='fs-2 text-primary'>
            <i className='bi bi-person-check-fill' />
          </div>
          <div className='fs-7 mt-1'>
            {t('system.content_1')}
          </div>
        </div>
        <div className='col-xl-4 p-4'>
          <div className='fs-2 text-primary'>
            <i className='bi bi-pc-display-horizontal' />
          </div>
          <div className='fs-7 mt-1'>
            {t('system.content_2')}
          </div>
        </div>
        <div className='col-xl-4 p-4'>
          <div className='fs-2 text-primary'>
            <i className='bi bi-person-bounding-box' />
          </div>
          <div className='fs-7 mt-1'>
            {t('system.content_3')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default System;