import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import CONTENT from './content.js';

const resources = CONTENT;
const getLocation = localStorage.getItem('lang-sending-domain');

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: (getLocation !== 'DE')
    || (getLocation !== 'FR')
    || (getLocation !== 'IT')
    || (getLocation !== 'ES')
    || (getLocation !== 'NL')
      ? 'EN' : getLocation,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;