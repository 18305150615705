const CONTENT = {
  EN: {
    translation: {
      header: {
        title: 'Sell Smarter, Win Faster',
        description: 'Navigate and win complex B2B deals with Client Focused Sales Systems',
        cta: 'Learn More'
      },
      system: {
        title: 'A Complete System',
        description: 'Accelerate Sales Success with a complete System to Sell, Train and Coach effectively',
        content_1: 'We help sales teams successfully navigate and win complex deals, sharpen sales acumen and accelerate sales execution with a powerful client centric sales platform, expert training and coaching tailored to your needs.',
        content_2: 'We equip sales teams with powerful and proven client centric sales methodology, strategic selling acumen and practices. Our holistic system optimizes sales performance and sales team enablement.',
        content_3: 'We help sales teams successfully navigate and win complex deals. A powerful sales platform facilitates better selling decisions, expert training sharpens sales acumen and coaching accelerates sales execution.'
      },
      newsletter: {
        title: 'Join Our Newsletter',
        cta: 'Subscribe',
        notification: 'Successfully subscribed!'
      },
      features: {
        title: 'Features',
        description: 'From analytics to coaching - We has it all.',
        content_1: {
          title: 'Professional Sales™',
          description: 'Pursue and Win winnable deals! Apply strategies to increase win chances, defer or disengage with built-in guidance from your personal sales system'
        },
        content_2: {
          title: 'Dedicated Academy',
          description: 'Enhance sales proficiency. Sharpen sales skills, acumen and professionalism with training curated to your goals, accessible anytime and anywhere'
        },
        content_3: {
          title: 'Coaching',
          description: 'Sell confidently and effectively with coaching and perspectives from managers, peers and Certified Coaches on demand'
        },
        content_4: {
          title: 'Consulting',
          description: 'Enable, equip and grow high performing sales teams with effective sales systems, process, methodology and expertise that bring out their best'
        }
      },
      footer: {
        title: 'Privacy Policy'
      }
    }
  },
  DE: {
    translation: {
      header: {
        title: 'Intelligenter verkaufen, schneller gewinnen',
        description: 'Navigieren und gewinnen Sie komplexe B2B-Geschäfte mit kundenorientierten Vertriebssystemen',
        cta: 'Erfahren Sie mehr'
      },
      system: {
        title: 'Ein komplettes System',
        description: 'Beschleunigen Sie den Verkaufserfolg mit einem kompletten System zum effektiven Verkaufen, Trainieren und Coachen',
        content_1: 'Wir helfen Vertriebsteams, komplexe Geschäfte erfolgreich zu navigieren und abzuschließen, den Verkaufssinn zu schärfen und die Verkaufsausführung mit einer leistungsstarken kundenorientierten Verkaufsplattform, Expertenschulungen und Coaching, die auf Ihre Bedürfnisse zugeschnitten sind, zu beschleunigen.',
        content_2: 'Wir statten Vertriebsteams mit leistungsstarker und bewährter kundenorientierter Vertriebsmethodik, strategischem Verkaufsgeschick und -praktiken aus. Unser ganzheitliches System optimiert die Vertriebsleistung und die Befähigung des Vertriebsteams.',
        content_3: 'Wir helfen Vertriebsteams, komplexe Geschäfte erfolgreich zu navigieren und erfolgreich abzuschließen. Eine leistungsstarke Verkaufsplattform erleichtert bessere Verkaufsentscheidungen, Expertenschulungen schärfen den Verkaufssinn und Coaching beschleunigt die Verkaufsabwicklung.'
      },
      newsletter: {
        title: 'Abonnieren Sie unseren Newsletter',
        cta: 'Abonnieren',
        notification: 'Erfolgreich abonniert!'
      },
      features: {
        title: 'Merkmale',
        description: 'Von Analytik bis Coaching - Wir haben alles.',
        content_1: {
          title: 'Professioneller Verkauf™',
          description: 'Verfolge und gewinne gewinnbare Deals! Wenden Sie Strategien an, um die Gewinnchancen zu erhöhen, verschieben oder lösen Sie sich mit der integrierten Anleitung Ihres persönlichen Verkaufssystems'
        },
        content_2: {
          title: 'Engagierte Akademie',
          description: 'Verbessern Sie die Verkaufskompetenz. Verbessern Sie Ihre Verkaufsfähigkeiten, Ihren Scharfsinn und Ihre Professionalität mit Schulungen, die auf Ihre Ziele abgestimmt sind und jederzeit und überall zugänglich sind'
        },
        content_3: {
          title: 'Coaching',
          description: 'Verkaufen Sie selbstbewusst und effektiv mit Coaching und Perspektiven von Managern, Kollegen und zertifizierten Coaches auf Anfrage'
        },
        content_4: {
          title: 'Beratung',
          description: 'Ermöglichen, statten und entwickeln Sie leistungsstarke Vertriebsteams mit effektiven Vertriebssystemen, Prozessen, Methoden und Fachkenntnissen, die ihr Bestes geben'
        }
      },
      footer: {
        title: 'Datenschutz-Bestimmungen'
      }
    }
  },
  FR: {
    translation: {
      header: {
        title: 'Vendez plus intelligemment, gagnez plus vite',
        description: 'Naviguez et gagnez des affaires B2B complexes avec des systèmes de vente axés sur le client',
        cta: 'Apprendre encore plus'
      },
      system: {
        title: 'Un système complet',
        description: 'Accélérez le succès des ventes avec un système complet pour vendre, former et coacher efficacement',
        content_1: 'Nous aidons les équipes de vente à naviguer avec succès et à remporter des contrats complexes, à aiguiser le sens des ventes et à accélérer l\'exécution des ventes grâce à une puissante plateforme de vente centrée sur le client, une formation d\'experts et un coaching adaptés à vos besoins.',
        content_2: 'Nous dotons les équipes de vente d\'une méthodologie de vente centrée sur le client puissante et éprouvée, d\'un sens aigu et de pratiques de vente stratégique. Notre système holistique optimise les performances de vente et l\'habilitation de l\'équipe de vente.',
        content_3: 'Nous aidons les équipes commerciales à naviguer avec succès et à remporter des contrats complexes. Une plate-forme de vente puissante facilite de meilleures décisions de vente, une formation d\'experts aiguise le sens des ventes et le coaching accélère l\'exécution des ventes.'
      },
      newsletter: {
        title: 'Rejoignez notre newsletter',
        cta: 'S\'abonner',
        notification: 'Abonné avec succès!'
      },
      features: {
        title: 'Caractéristiques',
        description: 'De l\'analyse au coaching - Nous avons tout.',
        content_1: {
          title: 'Ventes professionnelles™',
          description: 'Recherchez et gagnez des offres gagnantes ! Appliquez des stratégies pour augmenter les chances de gagner, différer ou désengager grâce aux conseils intégrés de votre système de vente personnel'
        },
        content_2: {
          title: 'Académie dédiée',
          description: 'Améliorez vos compétences en vente. Aiguisez vos compétences en vente, votre perspicacité et votre professionnalisme avec une formation adaptée à vos objectifs, accessible à tout moment et en tout lieu'
        },
        content_3: {
          title: 'Encadrement',
          description: 'Vendez en toute confiance et efficacement grâce au coaching et aux points de vue de managers, de pairs et de coachs certifiés sur demande'
        },
        content_4: {
          title: 'Consultant',
          description: 'Habiliter, équiper et développer des équipes de vente performantes avec des systèmes, des processus, une méthodologie et une expertise de vente efficaces qui font ressortir le meilleur d\'eux-mêmes'
        }
      },
      footer: {
        title: 'Politique de confidentialité'
      }
    }
  },
  IT: {
    translation: {
      header: {
        title: 'Vendi in modo più intelligente, vinci più velocemente',
        description: 'Naviga e vinci accordi B2B complessi con i sistemi di vendita incentrati sul cliente',
        cta: 'Saperne di più'
      },
      system: {
        title: 'Un sistema completo',
        description: 'Accelera il successo delle vendite con un sistema completo per vendere, formare e istruire in modo efficace',
        content_1: 'Aiutiamo i team di vendita a navigare con successo e vincere affari complessi, affinare l\'acume delle vendite e accelerare l\'esecuzione delle vendite con una potente piattaforma di vendita incentrata sul cliente, formazione di esperti e coaching su misura per le tue esigenze.',
        content_2: 'Forniamo ai team di vendita potenti e comprovate metodologie di vendita incentrate sul cliente, acume e pratiche di vendita strategica. Il nostro sistema olistico ottimizza le prestazioni di vendita e l\'abilitazione del team di vendita.',
        content_3: 'Aiutiamo i team di vendita a navigare con successo e vincere affari complessi. Una potente piattaforma di vendita facilita decisioni di vendita migliori, la formazione di esperti affina l\'acume delle vendite e il coaching accelera l\'esecuzione delle vendite.'
      },
      newsletter: {
        title: 'Iscriviti alla nostra newsletter',
        cta: 'sottoscrivi',
        notification: 'Abonné avec succès!'
      },
      features: {
        title: 'Caratteristiche',
        description: 'Dall\'analisi al coaching: abbiamo tutto.',
        content_1: {
          title: 'Vendite professionali™',
          description: 'Persegui e vinci affari vincibili! Applica strategie per aumentare le possibilità di vincita, rinviare o disimpegnarti con la guida integrata dal tuo sistema di vendita personale'
        },
        content_2: {
          title: 'Accademia dedicata',
          description: 'Migliora le competenze di vendita. Affina le capacità di vendita, l\'acume e la professionalità con una formazione curata in base ai tuoi obiettivi, accessibile sempre e ovunque'
        },
        content_3: {
          title: 'Istruire',
          description: 'Vendi in modo sicuro ed efficace con il coaching e le prospettive di manager, colleghi e coach certificati su richiesta'
        },
        content_4: {
          title: 'Consulenza',
          description: 'Abilita, dota e fai crescere i team di vendita ad alte prestazioni con sistemi di vendita, processi, metodologie e competenze efficaci che danno il meglio di sé'
        }
      },
      footer: {
        title: 'Politica sulla riservatezza'
      }
    }
  },
  ES: {
    translation: {
      header: {
        title: 'Venda de manera más inteligente, gane más rápido',
        description: 'Navegue y gane acuerdos B2B complejos con sistemas de ventas centrados en el cliente',
        cta: 'Aprende más'
      },
      system: {
        title: 'Un sistema completo',
        description: 'Acelere el éxito de ventas con un sistema completo para vender, capacitar y asesorar de manera efectiva',
        content_1: 'Ayudamos a los equipos de ventas a navegar con éxito y ganar negocios complejos, mejorar la perspicacia de ventas y acelerar la ejecución de ventas con una poderosa plataforma de ventas centrada en el cliente, capacitación experta y asesoramiento adaptado a sus necesidades.',
        content_2: 'Equipamos a los equipos de ventas con una poderosa y comprobada metodología de ventas centrada en el cliente, perspicacia y prácticas de ventas estratégicas. Nuestro sistema holístico optimiza el rendimiento de las ventas y la habilitación del equipo de ventas.',
        content_3: 'Ayudamos a los equipos de ventas a navegar con éxito y ganar acuerdos complejos. Una poderosa plataforma de ventas facilita mejores decisiones de venta, la capacitación de expertos agudiza la perspicacia de ventas y el entrenamiento acelera la ejecución de ventas.'
      },
      newsletter: {
        title: 'Suscríbase a nuestro boletín',
        cta: 'Suscribir',
        notification: 'Suscrita exitosamente!'
      },
      features: {
        title: 'Características',
        description: 'Desde análisis hasta coaching: lo tenemos todo.',
        content_1: {
          title: 'Ventas Profesionales™',
          description: '¡Persiga y gane tratos ganables! Aplique estrategias para aumentar las posibilidades de ganar, diferir o desconectarse con la guía integrada de su sistema de ventas personal'
        },
        content_2: {
          title: 'Academia dedicada',
          description: 'Mejorar la competencia de ventas. Mejore las habilidades de ventas, la perspicacia y la profesionalidad con capacitación seleccionada para sus objetivos, accesible en cualquier momento y en cualquier lugar'
        },
        content_3: {
          title: 'Entrenamiento',
          description: 'Venda con confianza y eficacia con el entrenamiento y las perspectivas de gerentes, compañeros y entrenadores certificados a pedido'
        },
        content_4: {
          title: 'Consultante',
          description: 'Habilite, equipe y desarrolle equipos de ventas de alto rendimiento con sistemas de ventas efectivos, procesos, metodología y experiencia que saquen lo mejor de sí mismos.'
        }
      },
      footer: {
        title: 'política de privacidad'
      }
    }
  },
  NL: {
    translation: {
      header: {
        title: 'Verkoop slimmer, win sneller',
        description: 'Navigeer en win complexe B2B-deals met klantgerichte verkoopsystemen',
        cta: 'Kom meer te weten'
      },
      system: {
        title: 'Een compleet systeem',
        description: 'Versnel verkoopsucces met een compleet systeem om effectief te verkopen, trainen en coachen',
        content_1: 'We helpen verkoopteams succesvol te navigeren en complexe deals binnen te halen, verkoopinzicht aan te scherpen en verkoopuitvoering te versnellen met een krachtig klantgericht verkoopplatform, deskundige training en coaching op maat van uw behoeften.',
        content_2: 'We rusten verkoopteams uit met krachtige en bewezen klantgerichte verkoopmethodologie, strategisch verkoopinzicht en -praktijken. Ons holistische systeem optimaliseert de verkoopprestaties en het inschakelen van het verkoopteam.',
        content_3: 'We helpen verkoopteams succesvol te navigeren en complexe deals binnen te halen. Een krachtig verkoopplatform maakt betere verkoopbeslissingen mogelijk, deskundige training scherpt het verkoopinzicht aan en coaching versnelt de verkoopuitvoering.'
      },
      newsletter: {
        title: 'Schrijf je in op onze nieuwsbrief',
        cta: 'Abonneren',
        notification: 'Succesvol geabonneerd!'
      },
      features: {
        title: 'Functies',
        description: 'Van analyse tot coaching - we hebben het allemaal.',
        content_1: {
          title: 'Professionele verkoop™',
          description: 'Achtervolg en win winbare deals! Pas strategieën toe om winkansen te vergroten, uit te stellen of uit te schakelen met ingebouwde begeleiding van uw persoonlijke verkoopsysteem'
        },
        content_2: {
          title: 'Toegewijde Academie',
          description: 'Verbeter de verkoopvaardigheid. Verbeter verkoopvaardigheden, scherpzinnigheid en professionaliteit met training die is samengesteld op basis van uw doelen en altijd en overal toegankelijk is'
        },
        content_3: {
          title: 'Coachen',
          description: 'Verkoop zelfverzekerd en effectief met coaching en perspectieven van managers, collega\'s en gecertificeerde coaches op aanvraag'
        },
        content_4: {
          title: 'Overleg plegen',
          description: 'Stel goed presterende verkoopteams in staat, rust ze uit en laat ze groeien met effectieve verkoopsystemen, processen, methodologie en expertise die het beste uit zichzelf halen'
        }
      },
      footer: {
        title: 'Privacybeleid'
      }
    }
  },
};

export default CONTENT;