const COMPANY = [
  {
    name: 'WONDERLEAD',
    info: `
      <h4>WONDERLEAD GLOBAL LTD</h4>
      27 Old Gloucester Street, WC1N 3AX, London (UK)<br>
      Company number 14628331<br>
      Legal Representative: Eugenio Russo<br>
      Contact: info@wonderlead.co.uk
    `,
    policy: `
      <h5>1. Data Protection – General Information</h5>
      General information
      <br><br>
      https://proprads.com/ is owned and operated by WONDERLEAD GLOBAL LTD, 27 Old Gloucester Street, WC1N 3AX, London (UK)
      (Company number 14628331) and we are committed to protecting and respecting your privacy. This privacy policy sets out
      the data processing practices carried out by us in relation to any personal information we hold relating to you.
      <br><br>
      Company name: WONDERLEAD GLOBAL LTD<br>
      Address: 27 Old Gloucester Street, WC1N 3AX, London (UK)<br>
      Company number: 14628331<br>
      Legal Representative: Eugenio Russo<br>
      Contact: info@wonderlead.co.uk
      <br><br>
      The following notes provide a simple overview of what happens to your personal data when you visit our website. Personal
      data is any data by which you can be personally identified. For detailed information on the subject of data protection,
      please refer to our data protection declaration listed below this text.
      <br><br>
      How do we collect your data?<br>
      Your data is collected by you providing it to us. This can be, for example, data that you enter in a contact form such
      as your email address. Other data is collected automatically by our IT systems when you visit the website. This is
      mainly technical data (e.g., Internet browser, operating system or time of page view). This data is collected
      automatically as soon as you enter our website.
      <br><br>
      What do we use your data for?<br>
      We use your data to send you email newsletters about exclusive deals, special offers and updates. Some of the data is
      collected to ensure error-free provision of the website. Other data may be used to analyze your user behavior.
      <br><br>
      List of Advertisers partners, from whom you may receive emails:
      <br><br>
      Auto<br>
      Auto1.com GmbH
      <br><br>
      Electricity & Gas<br>
      Bill Buddy<br>
      Clear Energy Switching<br>
      Bill Switchers<br>
      Evergen Solar<br>
      Switchd Ltd
      <br><br>
      Health ECHO Lloyds Pharmacy<br>
      Boots Hearingcare<br>
      Pharmacy2U
      <br><br>
      Insurance Quotezone
      <br><br>
      Services Marriage Tax Online<br>
      Verisure<br>
      The Medical Negligence Experts<br>
      Funeral Planning Experts
      <br><br>
      Shopping Fossil Europe GmbH<br>
      AllSaints Retail Limited<br>
      SkinLabo S.r.l<br>
      GoGroopie<br>
      Magnet Kitchens<br>
      SkinLabo S.r.l<br>
      Whisky Scotland Investment
      <br><br>
      Marketing Services Providers Sponsors
      <br><br>
      Lead 365 Ltd<br>
      6th Floor, Alexandra Warehouse, West Quay, Gloucester, Gloucestershire, GL1 2LG<br>
      Sector: Marketing Services, Data Processing http://www.lead365.co.uk/privacy-policy<br>
      Your rights in controlling how we use your data
      <br><br>
      (1) Under data protection law you have rights regarding how we process your data. Please use the following contact
      details to exercise any of the rights below:
      <br><br>
      Your right of access - You have the right to ask us for copies of your personal information. This right always applies.
      There are some exemptions, which means you may not always receive all the information we process.
      <br><br>
      Your right to rectification - You have the right to ask us to rectify information you think is inaccurate. You also have
      the right to ask us to complete information you think is incomplete. This right always applies.
      <br><br>
      Your right to erasure - You have the right to ask us to erase your personal information in certain circumstances.
      <br><br>
      Your right to restriction of processing - You have the right to ask us to restrict the processing of your information in
      certain circumstances.
      <br><br>
      Your right to object to processing - You have the right to object certain types of processing including withdrawing your
      consent for direct marketing, unless the processing forms part of our public tasks, or is in our legitimate interests.
      You can read more about this right here
      <br><br>
      Your right to data portability - This only applies to information you have given us. You have the right to ask that we
      transfer the information you gave us from one organisation to another, or give it to you.
      <br><br>
      (2) You also have the right to complain to a data protection supervisory authority about the processing of your personal
      data by us. In the UK, this is the Information Commissioner’s Office (ICO) and their contact details are:
      <br><br>
      The Information Commissioner’s Office<br>
      Wycliffe House, Water Lane, Wilmslow,<br>
      Cheshire SK9 5AF<br>
      0303 123 1113<br>
      http://ico.org.uk/
      <br><br>
      (3) Our data protection officer is available to answer questions about the processing of your personal data at:
      <br><br>
      Data Protection Officer of WONDERLEAD GLOBAL LTD - info@wonderlead.co.uk
      <br><br>
      Upon request, you will be informed in writing, in accordance with applicable law, whether and what personal data we have
      stored about you.
      <br><br>
      <h5>2. Collection of personal data when visiting our website</h5>
      (1) In the case of merely informational use of the website, i.e. if you do not register or otherwise transmit
      information to us, we only collect the personal data that your browser transmits to our server. If you wish to view our
      website, we collect the following data, which is technically necessary for us to display our website to you and to
      ensure stability and security (legal basis is Art.6 para.1 p.1 lit. F DS-GVO):
      <br><br>
      IP address<br>
      Date and time of the request<br>
      Time zone difference from Greenwich Mean Time (GMT)<br>
      Content of the request (specific page)<br>
      Access status/http status code<br>
      Amount of data transferred in each case<br>
      Website from which the request came Browser<br>
      Operating system and its interface<br>
      Language and version of the browser software.<br>
      (2) In addition to the aforementioned data, cookies are stored on your computer when you use our website. Cookies are
      small text files that are stored on your hard drive assigned to the browser you are using and through which the body
      that sets the cookie (here by us), certain information flows. Cookies cannot execute programs or transfer viruses to
      your computer. They serve to make the Internet offer as a whole more user-friendly and effective.
      <br><br>
      (3) Use of cookies:
      <br><br>
      a) This website uses the following types of cookies, the scope and functionality of which are explained below:
      <br><br>
      Transient cookies (for this purpose b)
      <br><br>
      Persistent cookies (c)
      <br><br>
      b) Transient cookies are automatically deleted when you close the browser. These include, in particular, session
      cookies. These store a so-called session ID, with which various requests of your browser can be assigned to the common
      session. This allows your computer to be recognized when you return to our website. The session cookies are deleted when
      you log out or close the browser.
      <br><br>
      c) Persistent cookies are deleted automatically after a specified period of time, which may differ depending on the
      cookie. You can delete the cookies in the security settings of your browser at any time.
      <br><br>
      d) You can configure your browser settings according to your preferences and, for example, refuse to accept third-party
      cookies or all cookies. Please note that you may not be able to use all functions of this website.
      <br><br>
      Analysis tools and third-party tools
      When visiting our website, your surfing behavior may be statistically analyzed. This is done primarily with cookies and
      with so-called analysis programs. The analysis of your surfing behavior is usually anonymous; the surfing behavior
      cannot be traced back to you. You can object to this analysis or prevent it by not using certain tools. You can find
      detailed information on this in the following privacy policy.
      <br><br>
      You can object to this analysis. We will inform you about the objection options in this privacy policy.
      <br><br>
      SSL or TLS encryption
      For security reasons and to protect the transmission of confidential content, such as orders or requests that you send
      to us as the site operator, this site uses SSL or TLS encryption. You can recognize an encrypted connection by the fact
      that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.
      <br><br>
      If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.
      <br><br>
      Information, blocking, deletion
      Within the framework of the applicable legal provisions, you have the right at any time to free information about your
      stored personal data, its origin and recipient and the purpose of data processing and, if necessary, a right to
      correction, blocking or deletion of this data. For this purpose, as well as for further questions on the subject of
      personal data, you can contact us at any time at the address given in the imprint.
      <br><br>
      <h5>3. Data Collection on our website</h5>
      Cookies<br>
      The Internet pages partly use so-called cookies. Cookies do not cause any damage to your computer and do not contain
      viruses. Cookies serve to make our offer more user-friendly, more effective and safer. Cookies are small text files that
      are stored on your computer and saved by your browser.
      <br><br>
      Most of the cookies we use are so-called "session cookies". They are automatically deleted after the end of your visit.
      Other cookies remain stored on your terminal device until you delete them. These cookies allow us to recognize your
      browser on your next visit.
      <br><br>
      You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual
      cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies
      when closing the browser. If cookies are deactivated, the functionality of this website may be limited.
      <br><br>
      Cookies that are required to carry out the electronic communication process or to provide certain functions you have
      requested (e.g. shopping cart function) are stored on the basis of Art. 6 (1) lit. f DSGVO. The website operator has a
      legitimate interest in storing cookies for the technically error-free and optimized provision of its services. Insofar
      as other cookies (e.g. cookies for the analysis of your surfing behavior) are stored, these are treated separately in
      this privacy policy.
      <br><br>
      CMP from Quantcast
      <br><br>
      In order to make it easier for you to select permitted cookies or, where necessary, to obtain your consent to the use of
      the cookies used, we use Quantcast Choice a GDPR-compliant consent management platform (CMP) from the provider Quantcast
      International Limited, Beaux Lane House, Lower Mercer Street, 1st Floor, Dublin 2, Ireland ("Quantcast"). You can access
      detailed privacy settings via a pop-up window and save the settings you have made for future visits to our website. For
      more information about Quantcast's privacy policy and cookies. Can be found at
      https://www.quantcast.com/de/datenschutz/.
      <br><br>
      Server log files<br>
      The provider of the pages automatically collects and stores information in so-called server log files, which your
      browser automatically transmits to us. These are:
      <br><br>
      browser type and browser version
      Operating system used
      referrer URL
      host name of the accessing computer
      Time of the server request
      IP address
      A combination of this data with other data sources is not made.<br>
      The basis for data processing is Art. 6 (1) lit. f DSGVO, which permits the processing of data for the fulfillment of a
      contract or pre-contractual measures.
      <br><br>
      Push Notifications
      For our website, we use OneSignal, a push messaging service provided by OneSignal Inc, 2194 Esperanca Avenue, Santa
      Clara, CA 95054, United States, (hereinafter 'ONESIGNAL') to send users push messages and to organize, optimize and
      customize the sending of push messages to our users. The personal data collected by ONESIGNAL includes your IP address
      and is transferred to and processed in the United States.
      <br><br>
      For more information, please see OneSignal's Privacy Policy.
      <br><br>
      The processing of your data via OneSignal is based on your consent (Art 6 para 1 lit a DSGVO).
      <br><br>
      You can opt out of receiving push messages by making the appropriate settings on your device.
      <br><br>
      <h5>4. Social Media</h5>
      Online presence in social media
      We maintain online presences within social networks and platforms in order to be able to communicate with customers,
      interested parties and users active there and to inform them about our services there.
      <br><br>
      We would like to point out that user data may be processed outside the European Union. This may result in risks for
      users because, for example, it could make it more difficult to enforce users' rights. With regard to U.S. providers that
      are certified under the Privacy Shield, we point out that they thereby undertake to comply with the data protection
      standards of the EU.
      <br><br>
      Furthermore, user data is usually processed for market research and advertising purposes. For example, usage profiles
      can be created from the usage behavior and resulting interests of the users. The usage profiles can in turn be used, for
      example, to place advertisements within and outside the platforms that presumably correspond to the interests of the
      users. For these purposes, cookies are usually stored on the users' computers, in which the usage behavior and interests
      of the users are stored. Furthermore, data may also be stored in the usage profiles regardless of the devices used by
      the users (especially if the users are members of the respective platforms and are logged in to them).
      <br><br>
      The processing of the users' personal data is based on our legitimate interests in effectively informing users and
      communicating with users pursuant to Art. 6 para. 1 lit. f. DSGVO. If users are asked by the respective providers for
      consent to data processing (i.e. declare their consent, e.g. by ticking a checkbox or confirming a button), the legal
      basis for processing is Art. 6 (1) lit. a., Art. 7 DSGVO.
      <br><br>
      For a detailed description of the respective processing and the opt-out options, please refer to the information of the
      providers linked below.
      <br><br>
      Also in the case of requests for information and the assertion of user rights, we point out that these can be asserted
      most effectively with the providers. Only the providers have access to the users' data and can take appropriate measures
      and provide information directly. If you still need help, you can contact us.
      <br><br>
      - Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland) - Privacy Policy:<br>
      https://www.facebook.com/about/privacy/, Opt-Out: https://www.facebook.com/settings?tab=ads and<br>
      https://www.youronlinechoices.com, Privacy Shield:<br>
      https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active.
      <br><br>
      - Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) - Privacy Policy:<br>
      https://policies.google.com/privacy, Opt-Out: https://adssettings.google.com/authenticated, Privacy Shield:<br>
      https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active.
      <br><br>
      - Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) - Privacy Policy/ Opt-Out:<br>
      https://instagram.com/about/legal/privacy/.
      <br><br>
      - Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) - Privacy Policy:<br>
      https://twitter.com/de/privacy, Opt-Out: https://twitter.com/personalization, Privacy Shield:<br>
      https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active.
      <br><br>
      - Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA) - Privacy Policy/ Opt-Out:<br>
      https://about.pinterest.com/de/privacy.
      <br><br>
      <h5>5. Analyss tools and advertising</h5>
      Google Analytics<br>
      This website uses functions of the web analysis service Google Analytics. The provider is Google Inc, 1600 Amphitheatre
      Parkway, Mountain View, CA 94043, USA.
      <br><br>
      Google Analytics uses so-called "cookies". These are text files that are stored on your computer and enable an analysis
      of your use of the website. The information generated by the cookie about your use of this website is usually
      transmitted to a Google server in the USA and stored there.
      <br><br>
      The storage of Google Analytics cookies is based on Art. 6 (1) lit. f DSGVO. The website operator has a legitimate
      interest in analyzing user behavior in order to optimize both its web offering and its advertising.
      <br><br>
      IP anonymization<br>
      We have activated the IP anonymization function on this website. This means that your IP address will be shortened by
      Google within member states of the European Union or in other states party to the Agreement on the European Economic
      Area before being transmitted to the USA. Only in exceptional cases will the full IP address be transmitted to a Google
      server in the USA and shortened there. On behalf of the operator of this website, Google will use this information for
      the purpose of evaluating your use of the website, compiling reports on website activity and providing other services
      relating to website activity and internet usage to the website operator. The IP address transmitted by your browser as
      part of Google Analytics will not be merged with any other data held by Google.
      <br><br>
      Browser Plugin<br>
      You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you
      do this you may not be able to use the full functionality of this website. You can also prevent the collection of data
      generated by the cookie and related to your use of the website (including your IP address) to Google and the processing
      of this data by Google by downloading and installing the browser plugin available at the following link:
      https://tools.google.com/dlpage/gaoptout?hl=de.
      <br><br>
      Order data processing<br>
      We have concluded an order data processing agreement with Google and fully implement the strict requirements of the
      German data protection authorities when using Google Analytics.
      <br><br>
      Demographic characteristics with Google Analytics
      <br><br>
      This website uses the "demographic characteristics" function of Google Analytics. This allows reports to be generated
      that contain statements about the age, gender and interests of site visitors. This data comes from interest-based
      advertising from Google as well as visitor data from third-party providers. This data cannot be assigned to a specific
      person. You can deactivate this function at any time via the ad settings in your Google account or generally prohibit
      the collection of your data by Google Analytics as shown in the item "Objection to data collection".
      <br><br>
      Objection to data collection<br>
      You can prevent the collection of your data by Google Analytics by clicking on the following link. An opt-out cookie
      will be set that prevents the collection of your data during future visits to this website (the opt-out only works in
      this browser and only for this domain). If you delete your cookies in this browser, you must click this link again:
      deactivate Google Analytics.
      <br><br>
      For more information on how Google Analytics handles user data, please see Google's privacy policy
      <br><br>
      Google AdSense<br>
      This website uses Google AdSense, an advertising integration service provided by Google Inc. ("Google"). The provider is
      Google Inc, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
      <br><br>
      Google AdSense uses "cookies", which are text files placed on your computer, to help the website analyze how users use
      the site. Google AdSense also uses so-called web beacons (invisible graphics). Through these web beacons, information
      such as visitor traffic on these pages can be analyzed.
      <br><br>
      The information generated by cookies and web beacons about the use of this website (including your IP address) and
      delivery of advertising formats will be transmitted to and stored by Google on servers in the United States. This
      information may be passed on by Google to contractual partners of Google. However, Google will not merge your IP address
      with other data stored by you.
      <br><br>
      The storage of AdSense cookies is based on Art. 6 para. 1 lit. f DSGVO. The website operator has a legitimate interest
      in analyzing user behavior in order to optimize both its web offering and its advertising.
      <br><br>
      You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you
      do this you may not be able to use the full functionality of this website. By using this website, you consent to the
      processing of data about you by Google in the manner and for the purposes set out above.
      <br><br>
      <h5>6. Newsletter</h5>
      Newsletter data<br>
      If you would like to receive the newsletter offered on the website, we require an e-mail address from you as well as
      information that allows us to verify that you are the owner of the e-mail address provided and that you agree to receive
      the newsletter. Further data is not collected or only on a voluntary basis. We use this data exclusively for sending the
      requested information and do not pass it on to third parties.
      <br><br>
      The processing of the data entered in the newsletter registration form is based exclusively on your consent (Art. 6
      para. 1 lit. a DSGVO). You can revoke your consent to the storage of the data, the e-mail address and their use for
      sending the newsletter at any time, for example via the "unsubscribe" link in the newsletter. The legality of the data
      processing operations already carried out remains unaffected by the revocation.
      <br><br>
      The data you provide for the purpose of receiving the newsletter will be stored by us until you unsubscribe from the
      newsletter and will be deleted after you unsubscribe from the newsletter. Data stored by us for other purposes (e.g.
      e-mail addresses for the member area) remain unaffected by this.
      <br><br>
      Newsletter - dispatch service provider<br>
      We use the dispatch service providers Episerver, Doktorsender, Spark Post and Mindbaz for the smooth dispatch.
      <br><br>
      These service providers enable us to send the newsletters you have subscribed to quickly and easily. In addition, the
      dispatch via these dispatch service providers allows us to analyze your opening and click behavior in the newsletter.
      This data is stored on a secure server.
      <br><br>
      If you are not interested in the storage or analysis of this data, you must unsubscribe from the newsletter. For this
      purpose, a corresponding link is provided in the newsletter.
      <br><br>
      The data processing is based on your consent (Art. 6 para. 1 lit. a DSGVO). You can revoke this consent at any time. The
      legality of the data processing operations already carried out remains unaffected by the revocation.
      <br><br>
      The data we have stored from you will remain on the server until you unsubscribe from the newsletter or request its
      deletion.
      <br><br>
      Further information can be found at
      <br><br>
      https://www.episerver.com/de/trust-center/datenschutz
      <br><br>
      https://www.sparkpost.com/policies/privacy/
      <br><br>
      https://doctorsender.com/politica-de-privacidad/
      <br><br>
      https://www.mindbaz.com/wp-content/uploads/2019/08/MINDBAZ_EN_Charte-de-confidentialite%CC%81_2019.pdf
      <br><br>
      <h5>7. Integration of services and contents of third parties</h5>
      Google Web Fonts<br>
      This site uses so-called web fonts provided by Google for the uniform display of fonts. When you call up a page, your
      browser loads the required web fonts into its browser cache in order to display texts and fonts correctly.
      <br><br>
      For this purpose, the browser you are using must connect to Google's servers. This enables Google to know that our
      website has been accessed via your IP address. Google Web Fonts are used in the interest of a uniform and appealing
      presentation of our online offers. This represents a legitimate interest within the meaning of Art. 6 (1) lit. f DSGVO.
      <br><br>
      If your browser does not support web fonts, a standard font will be used by your computer.
      <br><br>
      You can find more information about Google Web Fonts at https://developers.google.com/fonts/faq and in Google's privacy
      policy: https://www.google.com/policies/privacy/.
      <br><br>
      <h5>8. Data Security</h5>
      Servers located in Frankfurt and Iceland.
      <br><br>
      <h5>9. Storage Duration</h5>
      We store your data,
      <br><br>
      if you have consented to the processing, not longer than until you revoke your consent,
      if we need the data to execute a contract, at most for as long as the contractual relationship with you exists or
      statutory retention periods are in effect,
      if we use the data based on a legitimate interest, at most if your interest in erasure or anonymization does not
      outweigh it.
      <br><br>
      <h5>10. Changes to this Privacy Policy</h5>
      We may make changes to this privacy policy from time to time. You should check this page regularly to see our most up to
      date policy. This privacy policy was last updated 30/11/2022.
      <br><br>
    `
  },
  {
    name: 'FAVTRADE',
    info: `
      <h4>Favtrade Marketing Limited.</h4>
      Grangegorman Lower 2C, Smithfield, Dublin 7, Ireland<br>
      Director: Rebecca Nachisa Bujor<br>
      Company Number: 716927<br>
      E-mail: info@favtrade-marketing.com
    `,
    policy: `
      <p>We are very delighted that you have shown interest in our enterprise. Data protection is of a particularly high priority for the management of the Favtrade Marketing Limited. The use of the Internet pages of the Favtrade Marketing Limited is possible without any indication of personal data; however, if a data subject wants to use special enterprise services via our website, processing of personal data could become necessary. If the processing of personal data is necessary and there is no statutory basis for such processing, we generally obtain consent from the data subject.</p>

      <p>The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the General Data Protection Regulation (GDPR), and in accordance with the country-specific data protection regulations applicable to the Favtrade Marketing Limited. By means of this data protection declaration, our enterprise would like to inform the general public of the nature, scope, and purpose of the personal data we collect, use and process. Furthermore, data subjects are informed, by means of this data protection declaration, of the rights to which they are entitled.</p>
      
      <p>As the controller, the Favtrade Marketing Limited has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website. However, Internet-based data transmissions may in principle have security gaps, so absolute protection may not be guaranteed. For this reason, every data subject is free to transfer personal data to us via alternative means, e.g. by telephone. </p>
      
      <h4>1. Definitions</h4>
      <p>The data protection declaration of the Favtrade Marketing Limited is based on the terms used by the European legislator for the adoption of the General Data Protection Regulation (GDPR). Our data protection declaration should be legible and understandable for the general public, as well as our customers and business partners. To ensure this, we would like to first explain the terminology used.</p>
      
      <p>In this data protection declaration, we use, inter alia, the following terms:</p>
      
      <ul style='list-style: none'>
      <li><h4>a)    Personal data</h4>
      <p>Personal data means any information relating to an identified or identifiable natural person (“data subject”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
      </li>
      <li><h4>b) Data subject</h4>
      <p>Data subject is any identified or identifiable natural person, whose personal data is processed by the controller responsible for the processing.</p>
      </li>
      <li><h4>c)    Processing</h4>
      <p>Processing is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction. </p>
      </li>
      <li><h4>d)    Restriction of processing</h4>
      <p>Restriction of processing is the marking of stored personal data with the aim of limiting their processing in the future. </p>
      </li>
      <li><h4>e)    Profiling</h4>
      <p>Profiling means any form of automated processing of personal data consisting of the use of personal data to evaluate certain personal aspects relating to a natural person, in particular to analyse or predict aspects concerning that natural person's performance at work, economic situation, health, personal preferences, interests, reliability, behaviour, location or movements. </p>
      </li>
      <li><h4>f)     Pseudonymisation</h4>
      <p>Pseudonymisation is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separately and is subject to technical and organisational measures to ensure that the personal data are not attributed to an identified or identifiable natural person. </p>
      </li>
      <li><h4>g)    Controller or controller responsible for the processing</h4>
      <p>Controller or controller responsible for the processing is the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law. </p>
      </li>
      <li><h4>h)    Processor</h4>
      <p>Processor is a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller. </p>
      </li>
      <li><h4>i)      Recipient</h4>
      <p>Recipient is a natural or legal person, public authority, agency or another body, to which the personal data are disclosed, whether a third party or not. However, public authorities which may receive personal data in the framework of a particular inquiry in accordance with Union or Member State law shall not be regarded as recipients; the processing of those data by those public authorities shall be in compliance with the applicable data protection rules according to the purposes of the processing. </p>
      </li>
      <li><h4>j)      Third party</h4>
      <p>Third party is a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data.</p>
      </li>
      <li><h4>k)    Consent</h4>
      <p>Consent of the data subject is any freely given, specific, informed and unambiguous indication of the data subject's wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her. </p>
      </li>
      </ul>
      
      <h4>2. Name and Address of the controller</h4>
      <p>Controller for the purposes of the General Data Protection Regulation (GDPR), other data protection laws applicable in Member states of the European Union and other provisions related to data protection is:
      
      </p>
      
      <p>Favtrade Marketing Limited<br>
      Grangegorman Lower 2C Smithfield<br>
      D07 Dublin<br>
      Ireland<br>
      Phone: +35319638925<br>
      Email: info@favtrade-marketing.com<br>
      Website: www.favtrade-marketing.com</p>
      
      <h4>3. Cookies</h4>
      <p>The Internet pages of the Favtrade Marketing Limited use cookies. Cookies are text files that are stored in a computer system via an Internet browser.</p>
      
      <p>Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the dats subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID.</p>
      
      <p>Through the use of cookies, the Favtrade Marketing Limited can provide the users of this website with more user-friendly services that would not be possible without the cookie setting.</p>
      
      <p>By means of a cookie, the information and offers on our website can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to make it easier for users to utilize our website. The website user that uses cookies, e.g. does not have to enter access data each time the website is accessed, because this is taken over by the website, and the cookie is thus stored on the user's computer system. Another example is the cookie of a shopping cart in an online shop. The online store remembers the articles that a customer has placed in the virtual shopping cart via a cookie.</p>
      
      <p>The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable.</p>
      
      <h4>4. Collection of general data and information</h4>
      <p>The website of the Favtrade Marketing Limited collects a series of general data and information when a data subject or automated system calls up the website. This general data and information are stored in the server log files. Collected may be (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system reaches our website (so-called referrers), (4) the sub-websites, (5) the date and time of access to the Internet site, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system, and (8) any other similar data and information that may be used in the event of attacks on our information technology systems.</p>
      
      <p>When using these general data and information, the Favtrade Marketing Limited does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content of our website correctly, (2) optimize the content of our website as well as its advertisement, (3) ensure the long-term viability of our information technology systems and website technology, and (4) provide law enforcement authorities with the information necessary for criminal prosecution in case of a cyber-attack. Therefore, the Favtrade Marketing Limited analyzes anonymously collected data and information statistically, with the aim of increasing the data protection and data security of our enterprise, and to ensure an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from all personal data provided by a data subject.</p>
      
      <h4>5. Routine erasure and blocking of personal data</h4>
      <p>The data controller shall process and store the personal data of the data subject only for the period necessary to achieve the purpose of storage, or as far as this is granted by the European legislator or other legislators in laws or regulations to which the controller is subject to.</p>
      
      <p>If the storage purpose is not applicable, or if a storage period prescribed by the European legislator or another competent legislator expires, the personal data are routinely blocked or erased in accordance with legal requirements.</p>
      
      <h4>6. Rights of the data subject</h4>
      <ul style='list-style: none;'>
      <li><h4>a) Right of confirmation</h4>
      <p>Each data subject shall have the right granted by the European legislator to obtain from the controller the confirmation as to whether or not personal data concerning him or her are being processed. If a data subject wishes to avail himself of this right of confirmation, he or she may, at any time, contact any employee of the controller.</p>
      </li>
      <li><h4>b) Right of access</h4>
      <p>Each data subject shall have the right granted by the European legislator to obtain from the controller free information about his or her personal data stored at any time and a copy of this information. Furthermore, the European directives and regulations grant the data subject access to the following information:</p>
      
      <ul style='list-style: none;'>
      <li>the purposes of the processing;</li>
      <li>the categories of personal data concerned;</li>
      <li>the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular recipients in third countries or international organisations;</li>
      <li>where possible, the envisaged period for which the personal data will be stored, or, if not possible, the criteria used to determine that period;</li>
      <li>the existence of the right to request from the controller rectification or erasure of personal data, or restriction of processing of personal data concerning the data subject, or to object to such processing;</li>
      <li>the existence of the right to lodge a complaint with a supervisory authority;</li>
      <li>where the personal data are not collected from the data subject, any available information as to their source;</li>
      <li>the existence of automated decision-making, including profiling, referred to in Article 22(1) and (4) of the GDPR and, at least in those cases, meaningful information about the logic involved, as well as the significance and envisaged consequences of such processing for the data subject.</li>
      
      </ul>
      <p>Furthermore, the data subject shall have a right to obtain information as to whether personal data are transferred to a third country or to an international organisation. Where this is the case, the data subject shall have the right to be informed of the appropriate safeguards relating to the transfer.</p>
      
      <p>If a data subject wishes to avail himself of this right of access, he or she may, at any time, contact any employee of the controller.</p>
      </li>
      <li><h4>c) Right to rectification </h4>
      <p>Each data subject shall have the right granted by the European legislator to obtain from the controller without undue delay the rectification of inaccurate personal data concerning him or her. Taking into account the purposes of the processing, the data subject shall have the right to have incomplete personal data completed, including by means of providing a supplementary statement.</p>
      
      <p>If a data subject wishes to exercise this right to rectification, he or she may, at any time, contact any employee of the controller.</p></li>
      <li>
      <h4>d) Right to erasure (Right to be forgotten) </h4>
      <p>Each data subject shall have the right granted by the European legislator to obtain from the controller the erasure of personal data concerning him or her without undue delay, and the controller shall have the obligation to erase personal data without undue delay where one of the following grounds applies, as long as the processing is not necessary: </p>
      
      <ul style='list-style: none;'>
      <li>The personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed.</li>
      <li>The data subject withdraws consent to which the processing is based according to point (a) of Article 6(1) of the GDPR, or point (a) of Article 9(2) of the GDPR, and where there is no other legal ground for the processing.</li>
      <li>The data subject objects to the processing pursuant to Article 21(1) of the GDPR and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) of the GDPR. </li>
      <li>The personal data have been unlawfully processed.</li>
      <li>The personal data must be erased for compliance with a legal obligation in Union or Member State law to which the controller is subject.</li>
      <li>The personal data have been collected in relation to the offer of information society services referred to in Article 8(1) of the GDPR.</li>
      
      </ul>
      <p>If one of the aforementioned reasons applies, and a data subject wishes to request the erasure of personal data stored by the Favtrade Marketing Limited, he or she may, at any time, contact any employee of the controller. An employee of Favtrade Marketing Limited shall promptly ensure that the erasure request is complied with immediately.</p>
      
      <p>Where the controller has made personal data public and is obliged pursuant to Article 17(1) to erase the personal data, the controller, taking account of available technology and the cost of implementation, shall take reasonable steps, including technical measures, to inform other controllers processing the personal data that the data subject has requested erasure by such controllers of any links to, or copy or replication of, those personal data, as far as processing is not required. An employees of the Favtrade Marketing Limited will arrange the necessary measures in individual cases.</p>
      </li>
      <li><h4>e) Right of restriction of processing</h4>
      <p>Each data subject shall have the right granted by the European legislator to obtain from the controller restriction of processing where one of the following applies:</p>
      
      <ul style='list-style: none;'>
      <li>The accuracy of the personal data is contested by the data subject, for a period enabling the controller to verify the accuracy of the personal data. </li>
      <li>The processing is unlawful and the data subject opposes the erasure of the personal data and requests instead the restriction of their use instead.</li>
      <li>The controller no longer needs the personal data for the purposes of the processing, but they are required by the data subject for the establishment, exercise or defence of legal claims.</li>
      <li>The data subject has objected to processing pursuant to Article 21(1) of the GDPR pending the verification whether the legitimate grounds of the controller override those of the data subject.</li>
      
      </ul>
      <p>If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of the processing of personal data stored by the Favtrade Marketing Limited, he or she may at any time contact any employee of the controller. The employee of the Favtrade Marketing Limited will arrange the restriction of the processing. </p>
      </li>
      <li><h4>f) Right to data portability</h4>
      <p>Each data subject shall have the right granted by the European legislator, to receive the personal data concerning him or her, which was provided to a controller, in a structured, commonly used and machine-readable format. He or she shall have the right to transmit those data to another controller without hindrance from the controller to which the personal data have been provided, as long as the processing is based on consent pursuant to point (a) of Article 6(1) of the GDPR or point (a) of Article 9(2) of the GDPR, or on a contract pursuant to point (b) of Article 6(1) of the GDPR, and the processing is carried out by automated means, as long as the processing is not necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.</p>
      
      <p>Furthermore, in exercising his or her right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to have personal data transmitted directly from one controller to another, where technically feasible and when doing so does not adversely affect the rights and freedoms of others.</p>
      
      <p>In order to assert the right to data portability, the data subject may at any time contact any employee of the Favtrade Marketing Limited.</p>
      
      </li>
      <li>
      <h4>g) Right to object</h4>
      <p>Each data subject shall have the right granted by the European legislator to object, on grounds relating to his or her particular situation, at any time, to processing of personal data concerning him or her, which is based on point (e) or (f) of Article 6(1) of the GDPR. This also applies to profiling based on these provisions.</p>
      
      <p>The Favtrade Marketing Limited shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the establishment, exercise or defence of legal claims.</p>
      
      <p>If the Favtrade Marketing Limited processes personal data for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data concerning him or her for such marketing. This applies to profiling to the extent that it is related to such direct marketing. If the data subject objects to the Favtrade Marketing Limited to the processing for direct marketing purposes, the Favtrade Marketing Limited will no longer process the personal data for these purposes.</p>
      
      <p>In addition, the data subject has the right, on grounds relating to his or her particular situation, to object to processing of personal data concerning him or her by the Favtrade Marketing Limited for scientific or historical research purposes, or for statistical purposes pursuant to Article 89(1) of the GDPR, unless the processing is necessary for the performance of a task carried out for reasons of public interest.</p>
      
      <p>In order to exercise the right to object, the data subject may contact any employee of the Favtrade Marketing Limited. In addition, the data subject is free in the context of the use of information society services, and notwithstanding Directive 2002/58/EC, to use his or her right to object by automated means using technical specifications.</p>
      </li>
      <li><h4>h) Automated individual decision-making, including profiling</h4>
      <p>Each data subject shall have the right granted by the European legislator not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her, or similarly significantly affects him or her, as long as the decision (1) is not is necessary for entering into, or the performance of, a contract between the data subject and a data controller, or (2) is not authorised by Union or Member State law to which the controller is subject and which also lays down suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, or (3) is not based on the data subject's explicit consent.</p>
      
      <p>If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and a data controller, or (2) it is based on the data subject's explicit consent, the Favtrade Marketing Limited shall implement suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, at least the right to obtain human intervention on the part of the controller, to express his or her point of view and contest the decision.</p>
      
      <p>If the data subject wishes to exercise the rights concerning automated individual decision-making, he or she may, at any time, contact any employee of the Favtrade Marketing Limited.</p>
      
      </li>
      <li><h4>i) Right to withdraw data protection consent </h4>
      <p>Each data subject shall have the right granted by the European legislator to withdraw his or her consent to processing of his or her personal data at any time. </p>
      
      <p>If the data subject wishes to exercise the right to withdraw the consent, he or she may, at any time, contact any employee of the Favtrade Marketing Limited.</p>
      
      </li>
      </ul>
      <h4>7. Legal basis for the processing </h4>
      <p>Art. 6(1) lit. a GDPR serves as the legal basis for processing operations for which we obtain consent for a specific processing purpose. If the processing of personal data is necessary for the performance of a contract to which the data subject is party, as is the case, for example, when processing operations are necessary for the supply of goods or to provide any other service, the processing is based on Article 6(1) lit. b GDPR. The same applies to such processing operations which are necessary for carrying out pre-contractual measures, for example in the case of inquiries concerning our products or services. Is our company subject to a legal obligation by which processing of personal data is required, such as for the fulfillment of tax obligations, the processing is based on Art. 6(1) lit. c GDPR.
      In rare cases, the processing of personal data may be necessary to protect the vital interests of the data subject or of another natural person. This would be the case, for example, if a visitor were injured in our company and his name, age, health insurance data or other vital information would have to be passed on to a doctor, hospital or other third party. Then the processing would be based on Art. 6(1) lit. d GDPR.
      Finally, processing operations could be based on Article 6(1) lit. f GDPR. This legal basis is used for processing operations which are not covered by any of the abovementioned legal grounds, if processing is necessary for the purposes of the legitimate interests pursued by our company or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data. Such processing operations are particularly permissible because they have been specifically mentioned by the European legislator. He considered that a legitimate interest could be assumed if the data subject is a client of the controller (Recital 47 Sentence 2 GDPR).
      </p>
      
      <h4>8. The legitimate interests pursued by the controller or by a third party</h4>
      <p>Where the processing of personal data is based on Article 6(1) lit. f GDPR our legitimate interest is to carry out our business in favor of the well-being of all our employees and the shareholders.</p>
      
      <h4>9. Period for which the personal data will be stored</h4>
      <p>The criteria used to determine the period of storage of personal data is the respective statutory retention period. After expiration of that period, the corresponding data is routinely deleted, as long as it is no longer necessary for the fulfillment of the contract or the initiation of a contract.</p>
      
      <h4>10. Provision of personal data as statutory or contractual requirement; Requirement necessary to enter into a contract; Obligation of the data subject to provide the personal data; possible consequences of failure to provide such data </h4>
      <p>We clarify that the provision of personal data is partly required by law (e.g. tax regulations) or can also result from contractual provisions (e.g. information on the contractual partner).
      
      Sometimes it may be necessary to conclude a contract that the data subject provides us with personal data, which must subsequently be processed by us. The data subject is, for example, obliged to provide us with personal data when our company signs a contract with him or her. The non-provision of the personal data would have the consequence that the contract with the data subject could not be concluded.
      
      Before personal data is provided by the data subject, the data subject must contact any employee. The employee clarifies to the data subject whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data and the consequences of non-provision of the personal data.
      </p>
      
      <h4>11. Existence of automated decision-making</h4>
      <p>As a responsible company, we do not use automatic decision-making or profiling.</p>
      
      <p>Developed by the specialists for <a href='https://willing-able.com/'>LegalTech</a> at Willing & Able that also developed the system for <a href='https://abletocontract.com/'>document esign</a>. The legal texts contained in our privacy policy generator have been provided and published by <a href='https://dg-datenschutz.de/'>Prof. Dr. h.c. Heiko Jonny Maniero</a> from the German Association for Data Protection and <a href='https://www.wbs.legal/' rel='nofollow'>Christian Solmecke</a> from WBS law.</p>
    `
  },
  {
    name: "MC",
    info: `
      <h4>mailcommerce GmbH.</h4>
      Rupert-Mayer-Str. 44, 81379 MÃ¼nchen<br>
      Phone: +49 (0)89 235 193 931<br>
      Fax: +49 (0)89 411 47 28 - 29
    `,
    policy: `
      Mailcommerce GmbH takes privacy policy seriously and always aims to obtain and use all personal data according to the
      respective private data protection law. With this privacy policy declaration we would like to elucidate which data is
      absolutely necessary to work together and which voluntarily shared data will enable you to get extra benefits. Your
      data is only asked for and saved, when you explicitly have given your consent. We will send you a mail if changes in
      the dataprotection terms occur. In case you do not agree with the latest version you have the possibility to withdraw
      your acceptance.
      <br><br>
      PERSONAL INFORMATION THAT WE NEED TO DELIVER OUR SERVICES<br>
      In case you wish to use the services of mailcommerce GmbH just as a mere source of information without any
      registration, we will not need any personal data whatsoever from you. In case you want to receive any specific service
      you would have to register. As a standard part of the registration we will ask you the following information: your
      name, email address, your address and company.
      <br><br>
      USE OF YOUR NECESSARY DATA<br>
      First and foremost we use your personal data to provide you with the applications and services rendered by
      mailcommerce GmbH. Once you have explicitly agreed to it, your personal data will only be saved and used by
      mailcommerce GmbH or by authorized partners of mailcommerce GmbH. Mailcommerce GmbH logs your permission to collect,
      save and use the data. In case the rendering of services is tempory mailcommerce GmbH will erase your data at the end
      of the cooperation.
      <br><br>
      COLLECTION AND USE OF PERSONAL DATA<br>
      Personal data is only store, when you provide us with this information in order to e.g. draw a contract with us, run
      an auction or want to register for personalized services. As part of rendering of personalized services your
      registration data is used for publicity and market research purposes as well as shared with third parties – granted we
      have your approval. We will inform you separately which third parties we share your information with. This sharing
      also needs your explicit approval.
      <br><br>
      EXTENDED DATA COLLECTION, STORAGE AND USE<br>
      Once you give permission to mailcommerce GmbH to obtain and store additional personal data in order to give a
      personalized service, the company will store data not exceeding the absolutely necessary minimum according to the
      service demanded and will use it accordingly. Some of the services offered by mailcommerce GmbH are realized with the
      help of partners. In order to work effectively it is necessary to share your data with these partners in an anonymous
      form. This data is solely used by our partners to go through with the rendering of the service. We will inform you if
      any other company other than mailcommerce GmbH stores or administers your data.
      <br><br>
      TYPE OF DATA ANALYSIS<br>
      Mailcommerce GmbH analyses the behavior of its users. Mailcommerce GmbH may do this by themselves or third parties.
      These analysis are made in order to permanently upgrade the services to the client. All analyses will be carried out
      with anonymized data. No personal data will be used. We do not hand on personal data to the advertisers such as who,
      when and where a user clicked on an offer.
      <br><br>
      AGREEMENT<br>
      If however you do not grant your consent to individual services , we may not be able to provide you with the
      applications or services you have selected. To which applications and services this applies, you will hear in separate
      form for each of the particular services. We point out that the use of our services and applications can also be found
      with other providers and you have the opportunity to engage other providers.
      <br><br>
      USE OF COOKIES<br>
      Mailcommerce GmbH uses so called cookies. A cookie is a small piece of text data, that your web-browser files in your
      temporary internet files, in order for a supplier to realize an offer over the user function. Of course you got the
      option to block this cookie. Should you choose to deactivate this cookie, you will not be able to use many of the
      functions from mailcommerce GmbH.
      <br><br>
      USE OF GOOGLE ANALYTICS<br>
      This website uses Google Analytics, a web analytics service provided by Google Inc. (“Google”) Google Analytics uses
      so called “cookies”, text files that are stored on your computer and which enable an analysis of your use behavior on
      the website. The information generated by the cookie regarding your use of the website (including your IP address) is
      transmitted and stored to a Google server in the USA. Google will use this information to evaluate your use of the
      website, to compile reports on website activity for website operators and to provide others with website and internet
      related services. Google may also transfer this information to third parties within legal boundaries or where such
      third parties process the information on Google’s behalf. Google will not associate your IP address with any other
      data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, but we
      point out that you may not be able to use all features of this website in this case. By using this site, you agree to
      the processing of data about you by Google in the manner described above and for the aforementioned purpose.
      <br><br>
      USE OF GOOGLE MAPS<br>
      This website uses Google Maps API to visually display geographic information. When using Google Maps, Google also
      collects, processes and uses data about the use of map features by visitors. For more information about Google's data
      processing, please refer to https://www.google.de/intl/de/policies/privacy/.
      <br><br>
      EMAIL NEWSLETTER<br>
      If you register for our free newsletter, the data requested from you for this purpose, i.e. your email address and,
      optionally, your name and address, will be sent to us in order to regularly send you our email newsletter. If you no
      longer wish to receive a newsletter in the future, you can simply unsubscribe at any time, e.g. B. via email
      kontakt@mailcommerce.de or via the link to unsubscribe from the newsletter that you will find in every newsletter
      email. Your data will be deleted no later than 3 months after revoking your consent.
      <br><br>
      DATA PROCESSING IN CONNECTION WITH PARTICIPATION IN THE COMPETITION<br>
      Participation in the competition takes place via the landing page by submitting the answer of the game, first and last
      name, email and company name. The data will be used to carry out the competition including raffles, notification of
      the winner (by email) and sending of prizes. We also use the data to send you newsletters and advertising for our
      products. You can revoke your consent to data processing at any time.
      <br><br>
      LIST OF ADVERTISERS FROM WHOM YOU MAY RECEIVE EMAILS<br>
      Auto1.com GmbH<br>
      Fossil Europe GmbH<br>
      Verisure<br>
      AllSaints Retail Limited<br>
      Bill Buddy<br>
      Bill Switchers<br>
      Boots Hearingcare<br>
      Clear Energy Switching<br>
      ECHO Lloyds Pharmacy<br>
      Evergen Solar<br>
      Funeral Planning Experts<br>
      GoGroopie<br>
      Magnet Kitchens<br>
      Marriage Tax Online<br>
      Pharmacy2U<br>
      Phlo Pharmacy<br>
      Quotezone<br>
      SkinLabo S.r.l<br>
      Switchd Ltd<br>
      The Medical Negligence Experts<br>
      Whisky Scotland Investment<br>
      Neilson Financial Services (British Seniors Insurance)<br>
      Neilson Financial Services (Smart Insurance)<br>
      DELETE PERSONAL DATA<br>
      You have the rights to always revoke your consent to storage of your personal data in writing or electronically.
      Furthermore, you can always revoke your consent to the collection and storage of other voluntary data by mailcommerce
      GmbH. Our postal address: mailcommerce GmbH, Rupert-Mayer-Str. 44, 81241 München, Germany. Alternatively you can send
      us an email: kontakt@mailcommerce.de.
      <br><br>
      DATA SECURITY<br>
      All mailcommerce GmbH employees, who handle personal data, signed a non-disclosure agreement compliant to the §5 of
      the Federal Law of Data-Security at the beginning of their time working for mailcommerce GmbH. The company
      mailcommerce GmbH furthermore has implemented technical and organizational security measures to prevent manipulation,
      destruction or loss of personal data.
      <br><br>
      INFORMATION ABOUT STORED PERSONAL DATA<br>
      According to § § 19 and 34 BDSG (Federal Data Protection Act) we would be happy to give you free of charge and in
      written form information about your stored personal data. Please use for your information request, the prepared
      information request form.
      <br><br>
      FURTHER INFORMATION ON PRIVACY POLICY<br>
      Please be aware of when you fill in, by choice, personal information online (for example in chats or in e-mail), there
      is always a risk that this information might be used by third parties. Which means you might obtain news from
      undesired sources. Buying online, be cautious when filling in password and/or personal information.
      This translation is only to facilitate the understanding of the document, but has no legal value in a strict sense
      <br><br>
      Data protection officer<br>
      KASPAR-LUDWIG STOLZENHAIN LL.M. oec<br>
      Schöneberger Straße<br>
      1510963 Berlin<br>
      Contact: kontakt@mailcommerce.de.
    `
  }
];
export default COMPANY;