import {
  createBrowserRouter
} from 'react-router-dom';
import App from './App.js';
import Policy from './components/Policy.js';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/privacy-policy',
    element: <Policy />
  }
]);

export default router;