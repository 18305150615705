import React from 'react';
import featuresImg from '../images/main.jpg';
import { useTranslation } from 'react-i18next';

const Features = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='container' id='features'>
      <div className='row mt-5'>
        <div className='col-xl-12'>
          <div className='text-center'>
            <div className='fs-2'>
              {t('features.title')}
            </div>
            <p>
              {t('features.description')}
            </p>
          </div>
        </div>
      </div>

      <div className='row align-items-center mt-5'>
        <div className='col-xl-6'>
          <img src={featuresImg} alt='' />
        </div>
        <div className='col-xl-6'>
          <div className='item-features d-flex gap-3'>
            <i className='text-primary fs-3 bi bi-shield-fill-check' />
            <div>
              <h1 className='fs-5'>
                {t('features.content_1.title')}
              </h1>
              <p>
                {t('features.content_1.description')}
              </p>
            </div>
          </div>
          <div className='item-features d-flex gap-3'>
            <i className='text-primary fs-3 bi bi-shield-fill-check' />
            <div>
              <h1 className='fs-5'>
                {t('features.content_2.title')}
              </h1>
              <p>
                {t('features.content_2.description')}
              </p>
            </div>
          </div>
          <div className='item-features d-flex gap-3'>
            <i className='text-primary fs-3 bi bi-shield-fill-check' />
            <div>
              <h1 className='fs-5'>
                {t('features.content_3.title')}
              </h1>
              <p>
                {t('features.content_3.description')}
              </p>
            </div>
          </div>
          <div className='item-features d-flex gap-3'>
            <i className='text-primary fs-3 bi bi-shield-fill-check' />
            <div>
              <h1 className='fs-5'>
                {t('features.content_4.title')}
              </h1>
              <p>
                {t('features.content_4.description')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;