import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import COMPANY from '../company.js';

const Footer = ({ company }) => {
  const { t, i18n } = useTranslation();
  const [infoCompany, setInfoCompany] = useState();
  useEffect(() => {
    setInfoCompany(COMPANY.find(value => value.name === company));
  }, [company]);

  return (
    <footer className='mt-5 text-center bg-primary py-5 text-light'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12' dangerouslySetInnerHTML={{ __html: infoCompany && infoCompany.info }} />

          <p className='text-decoration-underline mt-2'
             style={{ cursor: 'pointer' }}>
            <Link to={`/privacy-policy?title=${infoCompany && infoCompany.name}`}>
              {t('footer.title')}
            </Link>
          </p>

        </div>
      </div>
    </footer>
  );
};

export default Footer;