import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './style.scss';
import Header from './components/Header.js';
import System from './components/System.js';
import Newsletter from './components/Newsletter.js';
import Features from './components/Features.js';
import Footer from './components/Footer.js';
import { getLocation } from './API/getLocation.js';
import { getDomain } from './API/getDomain.js';
import data from 'bootstrap/js/src/dom/data.js';

const App = () => {
  const [domainName, setDomainName] = useState('');
  const [companyName, setCompanyName] = useState('');
  useEffect(() => {
    getLocation().then(data => {
      localStorage.setItem('lang-sending-domain', data.iso_code);
    });
    getDomain(window.location.hostname).then(data => {
      setDomainName(data[0].domain.split('.').slice(-2, -1)[0]);
      setCompanyName(data[0].company_name)
    });
  }, []);

  return (
    <>
      <Header logo={domainName} />
      <System />
      <Newsletter />
      <Features />
      <Footer company={companyName}/>
    </>
  );
};

export default App;