import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import COMPANY from '../company.js';

const Policy = () => {
  const { t, i18n } = useTranslation();
  const [policy, setPolicy] = useState();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get('title');

    setPolicy(COMPANY.find(value => value.name === paramValue));
  });

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-xl-10'>
          <p className='mt-3 fs-2'>
            {t('footer.title')}
          </p>
          <div className='col-xl-12' dangerouslySetInnerHTML={{ __html: policy && policy.policy }} />
        </div>
      </div>
    </div>
  );
};

export default Policy;