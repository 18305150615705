import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';

const Newsletter = () => {
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <div className='registration mt-5'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-xl-6'>
            <div className='text-center'>
              <div className='fs-2 text-white'>
                {t('newsletter.title')}
              </div>
              <div className='email-user mt-2'>
                <input type='text' />
                <button onClick={() => setShow(true)} className='bg-primary text-white'>
                  {t('newsletter.cta')}
                </button>


                <Toast className='position-fixed' style={{ bottom: '20px', right: '20px' }}
                       onClose={() => setShow(false)} show={show} delay={3000} autohide>
                  <Toast.Body>
                    <div className='text-start'>
                      <i className='text-primary bi bi-check-circle-fill me-2'/> {t('newsletter.notification')}
                    </div>
                  </Toast.Body>
                </Toast>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;